<template>
  <div class="flex flex-col justify-center items-center w-full p-8">
    <h1 class="w-full text-center mb-8">Please log in to continue</h1>
    <form class="w-full max-w-sm">
      <div class="mb-6">
        <div>
          <label
            class="block text-gray-500 font-bold mb-1 md:mb-0 pr-4"
            for="username"
          >
            Username
          </label>
        </div>
        <div>
          <input
            v-model="username"
            type="text"
            name="username"
            id="username"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          />
        </div>
      </div>
      <div class="mb-6">
        <div>
          <label
            class="block text-gray-500 font-bold mb-1 md:mb-0 pr-4 whitespace-nowrap"
            for="tenant"
          >
            Tenant (not required)
          </label>
        </div>
        <div>
          <input
            v-model="tenantId"
            type="text"
            name="tenant"
            id="tenant"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          />
        </div>
      </div>
      <div class="mb-6">
        <div>
          <label
            for="inline-password"
            class="block text-gray-500 font-bold mb-1 md:mb-0 pr-4"
          >
            Password
          </label>
        </div>
        <div>
          <input
            v-model="password"
            type="password"
            name="password"
            id="password"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          />
        </div>
      </div>
      <div class="flex flex-row justify-end">
        <button
          type="button"
          @click="login"
          class="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
        >
          Log In
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from '@/store';
import { MutationTypes } from '@/store/mutations';
import { useRoute, useRouter } from 'vue-router';

interface User {
  username: string;
  password: string;
}

const users: User[] = [
  { username: 'mturk', password: 'LwMTb85J4ceR6Xf844' },
  { username: 'ec', password: 'qPUeJrF2y4X8YHVwom' },
  { username: 'pat', password: 'zK3S4f5VxC9jVw6qnT' },
  { username: 'oneworld', password: 'gVHbu9BL3jGecw3WEm' },
  { username: 'demo', password: '1db7qdsxkBs8xdjP98' },
  { username: 'qa', password: 'xkNwHqwGO0SPGxbmKB' },
  { username: 'test1', password: 'fGc0sH1p2fVVT6btuK' },
  { username: 'test2', password: 'AuIofaAH0RBN9WgyEK' },
  { username: 'test3', password: 'ftn3Su2YgTwl41sYJ9' },
  { username: 'userbrain', password: 'x64RLZicBAize7NQII' },
];

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const username = ref('');
    const tenantId = ref('');
    const password = ref('');

    username.value = route.query.username
      ? (route.query.username as string)
      : '';
    tenantId.value = route.query.tenantId
      ? (route.query.tenantId as string)
      : '';
    password.value = route.query.password
      ? (route.query.password as string)
      : '';

    async function login() {
      if (username.value !== '' && password.value !== '') {
        const isValidAuthentication = users.some(user => {
          return (
            user.username === username.value && user.password === password.value
          );
        });
        if (isValidAuthentication) {
          await store.commit(MutationTypes.SetAccessGranted, true);
          store.commit(MutationTypes.SetTenantId, tenantId.value);
          const query = route.query;
          // lets remove username and password as they are not needed anymore
          delete query.username;
          delete query.password;
          delete query.tenantId;
          // Redirect
          await router.replace({ name: 'Home', query: query });
        } else {
          alert('The username and/or password provided is incorrect.');
        }
      } else {
        alert('Username and Password are required.');
      }
    }

    onMounted(() => {
      const { username, password } = route.query;
      if (username && username !== '' && password && password !== '') {
        login();
      }
    });

    return {
      username,
      tenantId,
      password,
      login,
    };
  },
});
</script>
